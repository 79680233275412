/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {x: 0, y: 0}
  },
  routes: [

    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        {
          path: '/email',
          name: 'email',
          component: () => import('./views/email/Email.vue'),
          meta: {
            rule: 'editor'
          }
        },
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: '/',
          name: 'home',
          meta: {
            auth: true,
            breadcrumb: [
              {title: 'Home', active: true},
            ],
            pageTitle: 'Home',
          },
          component: () => import('./views/Home.vue')
        },
        {
          path: '/trips',
          name: 'trips',
          meta: {
            auth: {
              roles: 'trip_view',
              forbiddenRedirect: '/403'
            },
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Trips', active: true},
            ],
            pageTitle: 'Trips',
          },
          component: () => import('./views/trips/Trips.vue')
        },
        {
          path: '/attachments/:id',
          name: 'Attachment',
          meta: {
            auth: {
              roles: 'income_view',
              forbiddenRedirect: '/403'
            },
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Attachment', active: true},
            ],
            pageTitle: 'Attachment',
          },
          component: () => import('./views/transaction/Attachments.vue')
        },
        {
          path: '/income',
          name: 'Income',
          meta: {
            auth: {
              roles: 'income_view',
              forbiddenRedirect: '/403'
            },
            type: 'income',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Trips', url: '/trips'},
              {title: 'Income', active: true},
            ],
            pageTitle: 'Income',
          },
          component: () => import('./views/transaction/Transaction.vue')
        },
        {
          path: '/expense',
          name: 'Expense',
          meta: {
            auth: {
              roles: 'expense_view',
              forbiddenRedirect: '/403'
            },
            type: 'expense',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Trips', url: '/trips'},
              {title: 'Expense', active: true},
            ],
            pageTitle: 'Expense',
          },
          component: () => import('./views/transaction/Transaction.vue')
        },
        {
          path: '/gexpense',
          name: 'General Expense',
          meta: {
            auth: {
              roles: 'gexpense_view',
              forbiddenRedirect: '/403'
            },
            type: 'gexpense',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Trips', url: '/trips'},
              {title: 'General Expense', active: true},
            ],
            pageTitle: 'General Expense',
          },
          component: () => import('./views/transaction/Transaction.vue')
        },
        {
          path: '/gexpense/print',
          name: 'General_Expense_Print',
          meta: {
            auth: {
              roles: 'gexpense_view',
              forbiddenRedirect: '/403'
            },
            type: 'gexpense',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Trips', url: '/trips'},
              {title: 'General Expense/Print', active: true},
            ],
            pageTitle: 'General Expense Print',
          },
          component: () => import('./views/components/MonthlyExpensePrintView')
        },
        {
          path: '/trip/add',
          name: 'Add Trip',
          meta: {
            auth: {
              roles: 'trip_add',
              forbiddenRedirect: '/403'
            },
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'trips', url: '/trips'},
              {title: 'Add Trip', active: true},
            ],
            pageTitle: 'Add Trip',
          },
          component: () => import('./views/trips/TripAdd.vue')
        },
        {
          path: '/trip/edit',
          name: 'EditTrip',
          meta: {
            auth: {
              roles: 'trip_edit',
              forbiddenRedirect: '/403'
            },
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'trips', url: '/trips'},
              {title: 'Edit Trip', active: true},
            ],
            pageTitle: 'Edit Trip',
          },
          component: () => import('./views/trips/TripAdd.vue')
        },
        {
          path: '/trip/:id',
          name: 'trip_view',
          meta: {
            auth: {
              roles: 'trip_view',
              forbiddenRedirect: '/403'
            },
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Trips', url: '/trips'},
              {title: 'View Trip', active: true},
            ],
            pageTitle: 'Trips',
          },
          component: () => import('./views/trips/TripView.vue')
        },
        {
          path: '/ship/hm1',
          name: 'ship1',
          meta: {
            auth: {
              roles: 'ship_view',
              forbiddenRedirect: '/403'
            },
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Ship', url: '/ship/hm1'},
              {title: 'Hasan Hamim 1', active: true},
            ],
            pageTitle: 'Hasan Hamim 1',
            ship_id: 1
          },
          component: () => import('./views/ships/Ship.vue')
        },
        {
          path: '/ship/hm3',
          name: 'ship2',
          meta: {
            auth: {
              roles: 'ship_view',
              forbiddenRedirect: '/403'
            },
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Ship', url: '/ship/hm3'},
              {title: 'Hasan Hamim 3', active: true},
            ],
            pageTitle: 'Hasan Hamim 3',
            ship_id: 2
          },
          component: () => import('./views/ships/Ship.vue')
        },
        {
          path: '/users',
          name: 'users',
          meta: {
            auth: {
              roles: 'user_view',
              forbiddenRedirect: '/403'
            },
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Users', active: true},
            ],
            pageTitle: 'Users',
          },
          component: () => import('./views/users/User.vue')
        },
        {
          path: '/user/edit',
          name: 'user_edit',
          meta: {
            auth: {
              roles: 'user_edit',
              forbiddenRedirect: '/403'
            },
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Users', url: '/users'},
              {title: 'Edit', active: true},
            ],
            pageTitle: 'Edit User',
          },
          component: () => import('./views/users/UserAdd.vue')
        },
        {
          path: '/user/add',
          name: 'user_add',
          meta: {
            auth: {
              roles: 'user_add',
              forbiddenRedirect: '/403'
            },
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Users', url: '/users'},
              {title: 'Add', active: true},
            ],
            pageTitle: 'Add User',
          },
          component: () => import('./views/users/UserAdd.vue')
        },
        {
          path: '/roles',
          name: 'roles',
          meta: {
            auth: {
              roles: 'super_admin',
              forbiddenRedirect: '/403'
            },
            breadcrumb: [
              {title: 'Home', url: '/'},
              // { title: 'Roles', url: '/roles' },
              {title: 'Roles', active: true},
            ],
            pageTitle: 'Roles',
          },
          component: () => import('./views/role/Role.vue')
        },
        {
          path: '/roles/permission/:id',
          name: 'permission',
          meta: {
            auth: {
              roles: 'super_admin',
              forbiddenRedirect: '/403'
            },
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Roles', url: '/roles'},
              {title: 'Permission', active: true},
            ],
            pageTitle: 'Role Permission',
          },
          component: () => import('./views/role/Permission.vue')
        },

        //invoices

        {
          path: '/invoices/:id/edit',
          name: 'invoices.edit',
          meta: {
            auth: true,
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Invoices', url: '/invoices'},
              {title: 'Edit', active: true},
            ],
            pageTitle: 'Edit Invoice',
          },

          component: () => import('./views/invoice/EditInvoice.vue')
        },

        {
          path: '/invoices/:id/duplicate',
          name: 'invoices.duplicate',
          meta: {
            auth: true,
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Invoices', url: '/invoices'},
              {title: 'Duplicate', active: true},
            ],
            pageTitle: 'Duplicate Invoice',
          },

          component: () => import('./views/invoice/DuplicateInvoice.vue')
        },

        {
          path: '/invoices/add',
          name: 'invoices.add',
          meta: {
            auth: true,
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Invoices', url: '/invoices'},
              {title: 'Add', active: true},
            ],
            pageTitle: 'Add Invoice',
          },

          component: () => import('./views/invoice/AddInvoice.vue')
        },

        {
          path: '/invoices',
          name: 'invoices',
          meta: {
            auth: true,
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Invoices', active: true},
            ],
            pageTitle: 'Invoices',
          },

          component: () => import('./views/invoice/InvoiceIndex.vue')
        }

      ],
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/login',
          name: 'pageLogin',
          meta: {auth: false},
          component: () => import('@/views/pages/Login.vue')
        },
        ,
        {
          path: '/forgot-password',
          name: 'pageForgotPassword',
          component: () => import('@/views/pages/ForgotPassword.vue'),
          meta: {auth: false}
        },
        {
          path: '/password/reset/:token',
          name: 'pageResetPassword',
          component: () => import('@/views/pages/ResetPassword.vue'),
          meta: {auth: false}
        },
        {
          path: '/404',
          name: 'Error404',
          component: () => import('@/views/errors/Error404.vue')
        },
        {
          path: '/403',
          name: 'Error403',
          component: () => import('@/views/errors/Error403.vue')
        },
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/404'
    }
  ],
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = "none";
  }
})

export default router
